'use client'

import { Transition } from '@headlessui/react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import {
  Fragment,
  MouseEvent,
  UIEvent,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

enum EnDirection {
  LEFT = 'left',
  RIGHT = 'right',
}

const scrollStep = 150

export interface HorizontalScrollComponentHandles {
  updateScrollSize: () => void
}

const HorizontalScroll = memo(
  forwardRef(function HorizontalScroll(
    props: {
      children: React.ReactNode
      className?: string
      containerClassName?: string
    },
    fRef,
  ) {
    const containerRef = useRef<HTMLDivElement>(null)
    const [scrollData, setScrollData] = useState({
      isDown: false,
      startX: 0,
      scrollLeft: 0,
    })
    const [disableHandleScroll, setDisableHandleScroll] = useState(false)
    const [scrollSize, setScrollSize] = useState(0)

    const onClick = (direction: EnDirection) => {
      if (!containerRef.current) {
        return
      }
      const scrollLeft = containerRef.current.scrollLeft

      const value =
        direction === EnDirection.LEFT
          ? scrollLeft - scrollStep
          : scrollLeft + scrollStep

      const position = value < 0 ? 0 : value > scrollSize ? scrollSize : value
      containerRef.current.scrollTo({ left: position, behavior: 'smooth' })
      setScrollData((scrollData) => ({ ...scrollData, scrollLeft: position }))
    }

    const onMouseDown = useCallback((event: MouseEvent<HTMLDivElement>) => {
      const offsetLeft = containerRef.current?.offsetLeft ?? 0
      setScrollData({
        isDown: true,
        startX: event.pageX - offsetLeft,
        scrollLeft: containerRef.current?.scrollLeft ?? 0,
      })
    }, [])

    const onMouseMove = (event: MouseEvent<HTMLDivElement>) => {
      setDisableHandleScroll(true)
      if (!scrollData.isDown || !containerRef.current) {
        return
      }

      event.preventDefault()
      const x = event.pageX - containerRef.current.offsetLeft
      const walk = x - scrollData.startX
      containerRef.current.scrollLeft = scrollData.scrollLeft - walk
      containerRef.current.classList.add('scrolling')
    }

    const scrollFinish = () => {
      containerRef.current?.classList.remove('scrolling')
      setDisableHandleScroll(false)
      setScrollData({
        ...scrollData,
        isDown: false,
        scrollLeft: containerRef.current?.scrollLeft ?? 0,
      })
    }

    const updateScrollSize = () => {
      if (!containerRef.current) {
        return
      }
      setScrollSize(
        containerRef.current.scrollWidth - containerRef.current.clientWidth,
      )
    }

    useEffect(() => {
      updateScrollSize()
    }, [])

    useImperativeHandle(fRef, () => ({
      updateScrollSize: () => updateScrollSize(),
    }))

    const handleScroll = (event: UIEvent<HTMLDivElement>) => {
      if (disableHandleScroll) {
        return
      }

      setScrollData({
        ...scrollData,
        scrollLeft: event.currentTarget.scrollLeft,
      })
    }

    const buttonVerticalFull = true

    return (
      <div className={clsx('grid relative', props.className)}>
        <Transition
          as={Fragment}
          show={scrollData.scrollLeft < scrollSize && scrollSize > 0}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-x-2"
          enterTo="opacity-100 translate-x-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 translate-x-2"
        >
          <button
            className={clsx('absolute right-0 z-10 pl-4 hover:text-primary', {
              'inset-y-0 bg-[linear-gradient(to_left,var(--button-bg)_50%,transparent)]':
                buttonVerticalFull,
            })}
            onClick={() => onClick(EnDirection.RIGHT)}
          >
            <ChevronRightIcon className="w-5" />
          </button>
        </Transition>

        <Transition
          as={Fragment}
          show={scrollData.scrollLeft > 0 && scrollSize > 0}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 -translate-x-2"
          enterTo="opacity-100 translate-x-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 -translate-x-2"
        >
          <button
            className={clsx('absolute left-0 pr-4 z-10 hover:text-primary', {
              'inset-y-0 bg-[linear-gradient(to_right,var(--button-bg)_50%,transparent)]':
                buttonVerticalFull,
            })}
            onClick={() => onClick(EnDirection.LEFT)}
          >
            <ChevronLeftIcon className="w-5" />
          </button>
        </Transition>

        <div
          ref={containerRef}
          onMouseDown={onMouseDown}
          onMouseMove={onMouseMove}
          onMouseLeave={scrollFinish}
          onMouseUp={scrollFinish}
          onScroll={handleScroll}
          className={clsx(
            'overflow-x-auto md:no-scrollbar',
            props.containerClassName,
          )}
        >
          {props.children}
        </div>
      </div>
    )
  }),
)

export default HorizontalScroll
